module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Edopomoga","short_name":"Edopomoga","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"./src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2480cfecab806f3645ccaeb382145212"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-P31PC4DG1P"],"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"360012812909523"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
