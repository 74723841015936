import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useRules = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const rulesDoc = useMemo(
    () =>
      language === 'uk'
        ? 'klopotenko-rules'
        : 'klopotenko-rules-en',
    [language]
  );

  const rulesDocLink = `/documents/${rulesDoc}.pdf`;

  return {
    rulesDoc,
    rulesDocLink,
  };
};
